var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-blacker-layout',{attrs:{"column":""}},[_c('v-blacker-search',{attrs:{"items":_vm.searchFieldsConfig},on:{"reset":_vm.onResetSearch,"search":_vm.onSearch},scopedSlots:_vm._u([{key:"title-right",fn:function(){return [_c('v-blacker-layout',{attrs:{"align-center":""}},[_c('v-blacker-spacer'),_c('span',{staticClass:"vbt-margin-r-10",staticStyle:{"font-size":"17px"}},[_vm._v(" All accounts ")]),_c('v-blacker-switch',{staticClass:"custom-search-toggle",attrs:{"color":"info"},on:{"change":_vm.changeAccountsState}})],1)]},proxy:true}]),model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('v-blacker-content-box',{attrs:{"is-loading-process":_vm.isLoading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"go-back-btn":_vm.goBack,"title":"Information"}},[_c('v-blacker-table',{attrs:{"headers":_vm.alertsListHeaders,"items":_vm.alerts},scopedSlots:_vm._u([{key:"orderId",fn:function(ref){
var id = ref.item.orderId;
return [(id)?_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
            name: 'order',
            params: { id: id },
          }}},[_vm._v(" "+_vm._s(id)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"timestamp",fn:function(ref){
          var timestamp = ref.item.timestamp;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(timestamp,'local', '-'))+" ")]}}])}),_c('v-blacker-pagination',{attrs:{"items-count":_vm.pagedItemsCount,"total-count":_vm.alertsTotalCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }