import { mapState, mapActions, mapGetters } from 'vuex';

import { usePagination, useSearch, useDataLoading } from '@mixins/factories';

const dataLoadingOptions = { getterName: 'getAlerts' };
const paginationOptions = { itemsName: 'alerts' };
const searchOptions = {
  initialSearchDataStates: ['customerId', 'providerId', 'searchTerm', 'dates'],
  customTypes: {
    customerId: 'number',
    providerId: 'number',
    dates: 'array',
  },
};
const alertsListHeaders = Object.freeze([
  { text: 'Order ID', value: 'orderId' },
  { text: 'Timestamp', value: 'timestamp' },
  { text: 'Error', value: 'errorType' },
  { text: 'Comments', value: 'comments' },
]);

export default {
  name: 'Alerts',
  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],
  data() {
    return {
      alertsListHeaders,
    };
  },
  computed: {
    ...mapState('alerts', ['alerts', 'alertsTotalCount']),
    ...mapGetters('accounts', ['clientsOptions', 'providersOptions']),

    searchFieldsConfig() {
      return [
        {
          value: 'customerId',
          items: this.clientsOptions,
          label: 'Client',
          type: 'combobox',
          flex: 'lg6',
        },
        {
          value: 'providerId',
          items: this.providersOptions,
          label: 'Provider',
          type: 'combobox',
          flex: 'lg6',
        },
        {
          value: 'searchTerm', label: 'Order ID', type: 'text', flex: 'lg6',
        },
        {
          value: 'dates', label: 'Date Range', type: 'daterange', flex: 'lg6',
        },
      ];
    },
  },
  created() {
    this.getAccounts({ activeOnly: true });
  },
  methods: {
    ...mapActions('alerts', ['getAlerts']),
    ...mapActions('accounts', ['getAccounts']),

    changeAccountsState(value) {
      this.getAccounts({ activeOnly: !value });
    },
  },
};
